import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Heading, Table } from '@loomispay/vault';
import { FormSelect, SupportBackofficeSelect } from 'components/Select';
import { FormTextInput } from 'components/TextInput';
import { Permission, useUserPermissions } from 'permissions';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseButton, FlexDiv, Form } from 'routes/fees/helper';
import useSWR from 'swr';
import { formatAmount } from 'utils/formatters/amountFormatters';
import { formatISODateTime } from 'utils/formatters/dateFormatters';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { createToast } from 'vercel-toast';
import { z } from 'zod';
import { fetchByUrl } from '../../../api';
import { Modal } from '../../../components/Modal';
import { fetchFromBackend } from '../../../utils/fetch';
import { toUrl } from '../../../utils/formatters/toUrl';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { FilteringDiv, FlexLeftCol, FlexRightCol } from './MerchantTerminalsTab.styles';
import { useMerchant } from '../merchantReporting-hooks';
import { AcquirerOnboardingState, StatusCode, Terminal, TerminalsResponse, TerminalStatus } from '../../../api/types';
import { ConfirmationDialog } from '../status/common/ConfirmationDialog';

interface FreeSubscriptionMonthsAdded {
  merchantId: string;
  terminalIds: string[];
  addedFreeMonths: number;
}

export const MerchantTerminalsTab = () => {
  const { t } = useTranslation();

  enum TerminalsFilter {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
  }

  const merchantId = useMerchantIdFromUrl();
  const { permissions } = useUserPermissions();
  const { data: merchant } = useMerchant(merchantId);
  const [statusValue, setStatusValue] = useState(TerminalsFilter.ACTIVE);

  const { data, mutate } = useSWR<TerminalsResponse>(
    [
      toUrl('/terminals', [
        ['merchantId', merchantId],
        ['terminalsFilter', statusValue],
      ]),
      statusValue,
    ],
    url => fetchByUrl(url),
    { refreshInterval: 10000 }
  );

  const formatData =
    data?.terminals
      .sort((a, b) => {
        const comparison = a.createdAt.localeCompare(b.createdAt);
        if (comparison === 0) {
          return a.terminalId.localeCompare(b.terminalId);
        }
        return comparison;
      })
      .map(it => ({
        ...it,
        createdAt: formatISODateTime(it.createdAt),
        subscriptionPrice: formatAmount(it.subscriptionPrice, it.currency),
      })) ?? [];

  const [loadModal, setLoadModal] = useState<boolean>(false);

  const [inactivateModalOpen, setStatusChangeModalOpen] = useState<boolean>(false);
  const [selectedTerminal, setSelectedTerminal] = useState<Terminal | null>(null);

  const columns = [
    { Header: t('terminal.table.terminal'), accessor: 'terminalId', minWidth: 310 },
    { Header: t('terminal.table.model'), accessor: 'model' },
    { Header: t('terminal.table.store'), accessor: 'store' },
    { Header: t('terminal.table.subscription-price'), accessor: 'subscriptionPrice' },
    { Header: t('terminal.table.total-compensated-months'), accessor: 'totalCompensatedMonths' },
    { Header: t('terminal.table.created-at'), accessor: 'createdAt' },
    {
      Header: t('terminal.table.acquirer-onboarding-state'),
      accessor: 'acquirerOnboardingState',
      Cell: (props: { value: AcquirerOnboardingState }) => props.value && toTitleCase(props.value),
    },
    {
      Header: t('terminal.table.name'),
      accessor: 'name',
    },
    {
      Header: ' ',
      id: 'actions',
      Cell: (props: { row: { original: Terminal } }) => {
        const terminal = props.row.original;
        if (!permissions.includes(Permission.WRITE_TERMINAL_FREE_MONTHS)) return null;

        if (terminal.isActive) {
          return (
            <Button
              label={t('terminal.inactivate')}
              size="s"
              variant={'destructive'}
              disabled={!terminal.isActive}
              onClick={() => {
                setSelectedTerminal(terminal);
                setStatusChangeModalOpen(true);
              }}
            />
          );
        } else {
          return (
            <Button
              label={t('terminal.activate')}
              size="s"
              variant="secondary"
              disabled={terminal.isActive}
              onClick={() => {
                setSelectedTerminal(terminal);
                setStatusChangeModalOpen(true);
              }}
            />
          );
        }
      },
    },
  ];

  const handleTerminalStatusChange = async (newActiveStatus: boolean) => {
    if (!selectedTerminal) return;

    const response = await fetchFromBackend(
      `/terminals/${selectedTerminal.terminalId}/status?active=${newActiveStatus}`,
      {
        method: 'PUT',
      }
    );

    if (response.ok) {
      setStatusChangeModalOpen(false);
      setSelectedTerminal(null);
      createToast(newActiveStatus ? t('terminal.activate-success') : t('terminal.inactivate-success'), {
        timeout: 6000,
        type: 'success',
      });
      await mutate();
    } else if (response.status >= 500) {
      createToast(t('unexpected-error'), {
        timeout: 6000,
        type: 'error',
      });
    } else if (response.status >= 400) {
      createToast(t('bad-data-format'), {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  return (
    <>
      <FilteringDiv>
        <SupportBackofficeSelect
          options={Object.values(TerminalsFilter).map(it => ({
            value: it,
            label: toTitleCase(it),
          }))}
          label={t('terminal.status.filter')}
          defaultValue={{ value: statusValue, label: toTitleCase(statusValue) }}
          onSelectionChange={status => {
            setStatusValue(status?.toUpperCase() as TerminalsFilter);
          }}
        />
      </FilteringDiv>
      <Table columns={columns} data={formatData} />
      <ConfirmationDialog
        onConfirm={() => handleTerminalStatusChange(!selectedTerminal?.isActive)}
        onCancel={() => {
          setStatusChangeModalOpen(false);
          setSelectedTerminal(null);
        }}
        isOpen={inactivateModalOpen}
        text={
          selectedTerminal?.isActive ? t('terminal.inactivate-confirm-message') : t('terminal.activate-confirm-message')
        }
      />

      {data &&
        merchant &&
        merchant?.onboarding?.contractSignedAt &&
        merchant?.merchant?.status?.code &&
        ![
          StatusCode.DEACTIVATED,
          StatusCode.OFFBOARDING_REQUESTED,
          StatusCode.OFFBOARDED,
          StatusCode.ANNULLED,
        ].includes(merchant?.merchant?.status?.code) &&
        permissions.includes(Permission.WRITE_TERMINAL_FREE_MONTHS) && (
          <>
            <Button
              label="Compensate"
              size={'m'}
              onClick={() => {
                setLoadModal(true);
              }}
            />
            <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
              <ModalContent
                terminals={data.terminals.filter(t => t.status === TerminalStatus.COMPLETE)}
                setLoadModal={setLoadModal}
              />
            </Modal>
          </>
        )}
    </>
  );
};

interface ModalContentProps {
  terminals: Terminal[];
  setLoadModal: (value: boolean) => void;
}

const ModalContent: FunctionComponent<ModalContentProps> = ({ terminals, setLoadModal }) => {
  const merchantId = useMerchantIdFromUrl();
  const { t } = useTranslation();

  const FormInputSchema = z.object({
    terminals: z
      .array(
        z.object({
          value: z.string().nonempty(),
          label: z.any(),
        })
      )
      .min(1)
      .max(20),
    addedFreeMonths: z.preprocess(a => parseInt(z.string().parse(a), 10), z.number().min(1).max(12)),
  });

  type FormInputSchemaType = z.infer<typeof FormInputSchema>;

  const { control, handleSubmit } = useForm<FormInputSchemaType>({
    defaultValues: {
      addedFreeMonths: undefined,
    },
    resolver: zodResolver(FormInputSchema),
  });

  const onSubmit = async (data: FormInputSchemaType) => {
    const reqBody: FreeSubscriptionMonthsAdded = {
      merchantId,
      terminalIds: data.terminals.map(terminal => terminal.value),
      addedFreeMonths: data.addedFreeMonths,
    };

    fetchFromBackend('/terminals/compensate', {
      method: 'PUT',
      body: JSON.stringify(reqBody),
    }).then(response => {
      if (response.ok) {
        setLoadModal(false);
      } else if (response.status > 500)
        createToast(t('unexpected-error'), {
          timeout: 6000,
          type: 'error',
        });
      else if (response.status >= 400)
        createToast(t('bad-data-format'), {
          timeout: 6000,
          type: 'error',
        });
    });
  };

  return (
    <>
      <Heading size={'m'}>{t('merchant.terminal.compensate-header')}</Heading>
      <Heading size={'xs'}>{t('merchant.terminal.compensate-sub-header')}</Heading>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FlexDiv>
          <FlexLeftCol>
            <FormSelect
              options={
                terminals.map(it => ({
                  value: it.terminalId,
                  label: (
                    <div>
                      {it.terminalId} | {it.model} | {it.store} | {formatAmount(it.subscriptionPrice, it.currency)}{' '}
                    </div>
                  ),
                })) ?? []
              }
              label={t('merchant.terminal.terminals-label')}
              name="terminals"
              control={control}
              isMulti={true}
              required={true}
            />
          </FlexLeftCol>
          <FlexRightCol>
            <FormTextInput
              placeholder={'1-12'}
              name="addedFreeMonths"
              control={control}
              label={t('merchant.terminal.added-free-months')}
              required={true}
              type={'number'}
            />
          </FlexRightCol>
        </FlexDiv>
        <div style={{ float: 'right' }}>
          <Button type={'submit'} label={t('merchant.terminal.save-changes')} />
        </div>
        <CloseButton>
          <Button
            label={t('merchant.terminal.cancel')}
            variant={'tertiary'}
            onClick={() => {
              setLoadModal(false);
            }}
          />
        </CloseButton>
      </Form>
    </>
  );
};
