import 'react-datepicker/dist/react-datepicker.css';

import { DatePicker, Icon } from '@loomispay/vault';
import { useCallback, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDateRange } from 'utils/formatters/formatDateRange';

type FormDatePickerProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
};

type MonthYearPickerProps = FormDatePickerProps & {
  selected?: Date;
  maxDate?: Date;
};

type InternalDatePickerProps = {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (value: [Date | null, Date | null]) => void;
};

type SingleDatePickerProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  minDate: Date | null;
  maxDate: Date | null;
  defaultValue?: Date | null;
  required: boolean | false;
  monthYearPicker?: boolean | false;
};

export const FormDatePicker = ({ name, control }: FormDatePickerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InternalDatePicker
          onChange={value => field.onChange({ start: value[0], end: value[1] })}
          startDate={field.value.start}
          endDate={field.value.end}
        />
      )}
    ></Controller>
  );
};

export const SingleDatePicker = ({
  name,
  control,
  minDate,
  maxDate,
  defaultValue,
  required,
  monthYearPicker,
}: SingleDatePickerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DatePicker
          showPopperArrow={true}
          placeholderText="Select date"
          dateFormat="dd-MM-yyyy"
          selectsRange={false}
          selected={defaultValue}
          minDate={minDate}
          maxDate={maxDate}
          required={required}
          onChange={(value: Date | null) => field.onChange(value)}
          useCustomMonthYearPicker={monthYearPicker}
        />
      )}
    ></Controller>
  );
};

const InternalDatePicker = ({ onChange, startDate, endDate }: InternalDatePickerProps) => {
  const { t } = useTranslation();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const calendarCustomInput = GetCalendarCustomInput(calendarOpen);

  const handleCalendarClose = () => setCalendarOpen(false);
  const handleCalendarOpen = () => setCalendarOpen(true);

  const onChangeHandler = useCallback(
    value => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  function GetCalendarCustomInput(calendarOpen: boolean) {
    if (calendarOpen) {
      return (
        <CalendarOpen>
          <Icon name="calendar" size="m" />
          {formatDateRange(startDate, endDate) || t('invoices.date-picker.select-range')}
          <span style={{ float: 'right' }}>
            <Icon name="chevronUp" size="m" />
          </span>
        </CalendarOpen>
      );
    } else {
      return (
        <CalendarClosed>
          <Icon name="calendar" size="m" />
          {formatDateRange(startDate, endDate) || t('invoices.date-picker.select-range')}
          <span style={{ float: 'right' }}>
            <Icon name="chevronDown" size="m" />
          </span>
        </CalendarClosed>
      );
    }
  }

  return (
    <DatePicker
      showPopperArrow={true}
      placeholderText="Select date range"
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onCalendarClose={handleCalendarClose}
      onCalendarOpen={handleCalendarOpen}
      onChange={onChangeHandler}
      customInput={calendarCustomInput}
    />
  );
};

export { InternalDatePicker as DatePicker };

export const MonthYearPicker = ({ name, control, selected, maxDate }: MonthYearPickerProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <DatePicker
            showPopperArrow={true}
            placeholderText={t('date-picker.month.select')}
            selectsRange={false}
            dateFormat="MMM yyyy"
            onChange={(value: Date | null) => field.onChange(value)}
            showMonthYearPicker={true}
            selected={selected}
            maxDate={maxDate}
          />
        );
      }}
    />
  );
};

const CalendarClosed = styled.div`
  padding: 7px 10px 7px 12px;
  border: 1px solid #a8a59d;
  border-radius: 4px;
  font-family: 'Roobert';
  color: #121212;
`;

const CalendarOpen = styled.div`
  padding: 7px 10px 7px 12px;
  border-radius: 4px;
  border: 1px solid #121212;
  outline: 1px solid #121212 !important;
  box-shadow: none;
  font-family: 'Roobert';
`;
