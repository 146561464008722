import { Table, Text } from '@loomispay/vault';
import { useMerchant } from '../merchants/merchantReporting-hooks';
import { useTranslation } from 'react-i18next';
import { formatISODateTime } from '../../utils/formatters/dateFormatters';
import { hasAnyPermission, Permission, useUserPermissions } from '../../permissions';
import { ApproveBankAccountChangeButton } from './ApproveBankAccountChangeButton';
import { RejectBankAccountChangeButton } from './RejectBankAccountChangeButton';
import React from 'react';
import styled from 'styled-components';

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  text-wrap: wrap;
`;

export enum RequestStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface GetBankAccountInformationUpdateRequests {
  data: BankAccountInformationUpdateRequest[];
}

export interface BankAccountInformationUpdateRequest {
  id: string;
  createdAt: string;
  merchantId: string;
  salesforceTicketId: string;
  triggeredByUser: string;
  status: RequestStatus;
  needsAttention: boolean;
  updatedByUser: string | null | undefined;
  updatedAt: string | null | undefined;
  bankIdentificationCode: string;
  bankAccountNumber: string;
  accountOwner: string;
  country: string;
}

interface Props {
  bankAccountUpdateRequests: BankAccountInformationUpdateRequest[];
  refresh: () => void;
}

export const BankAccountInformationUpdateRequests = ({ bankAccountUpdateRequests, refresh }: Props) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  const columns = [
    {
      Header: t('bank-account-update-requests.table.merchant-id'),
      accessor: 'merchantId',
      minWidth: 310,
    },
    {
      Header: t('bank-account-update-requests.table.salesforce-ticket-id'),
      accessor: 'salesforceTicketId',
      width: 160,
    },
    {
      Header: t('bank-account-update-requests.table.bank-details'),
      accessor: 'bankDetails',
      minWidth: 260,
    },
    { Header: t('bank-account-update-requests.table.created-at'), accessor: 'createdAt' },
    { Header: t('bank-account-update-requests.table.created-by'), accessor: 'createdBy' },
    { Header: t('bank-account-update-requests.table.updated-at'), accessor: 'updatedAt' },
    { Header: t('bank-account-update-requests.table.updated-by'), accessor: 'updatedBy' },
    { Header: t('bank-account-update-requests.table.status'), accessor: 'status', width: 100 },
    { accessor: 'approve', width: 90 },
    { accessor: 'reject', width: 80 },
  ];

  function isActionButtonEnabled(status: RequestStatus, country: string): boolean {
    return (
      status === RequestStatus.NEW &&
      hasAnyPermission(permissions, [('UPDATE_BANK_ACCOUNT_INFORMATION_' + country) as Permission])
    );
  }

  const tableData =
    bankAccountUpdateRequests.map(it => ({
      ...it,
      merchantId: <MerchantCell {...it} />,
      salesforceTicketId: <FlexCol>{highlightTextIfNeedsAttention(it.needsAttention, it.salesforceTicketId)}</FlexCol>,
      bankDetails: <BankDetailsCell {...it} />,
      createdAt: <FlexCol>{highlightTextIfNeedsAttention(it.needsAttention, formatISODateTime(it.createdAt))}</FlexCol>,
      createdBy: <FlexCol>{highlightTextIfNeedsAttention(it.needsAttention, it.triggeredByUser)}</FlexCol>,
      updatedAt: <FlexCol>{highlightTextIfNeedsAttention(it.needsAttention, formatISODateTime(it.updatedAt))}</FlexCol>,
      updatedBy: <FlexCol>{highlightTextIfNeedsAttention(it.needsAttention, it.updatedByUser)}</FlexCol>,
      status: highlightTextIfNeedsAttention(it.needsAttention, it.status),
      approve: isActionButtonEnabled(it.status, it.country) && (
        <ApproveBankAccountChangeButton request={it} onAction={refresh} />
      ),
      reject: isActionButtonEnabled(it.status, it.country) && (
        <RejectBankAccountChangeButton request={it} onAction={refresh} />
      ),
    })) || [];

  return (
    <div>
      <Table
        columns={columns}
        data={tableData}
        getRowProps={() => ({
          style: {
            minHeight: 80,
          },
        })}
      />
    </div>
  );
};

function highlightTextIfNeedsAttention(needsAttention: boolean, value: string | undefined | null) {
  if (needsAttention) {
    return (
      <Text size={'s'} color={'negative'} weight={'semiBold'}>
        {value}
      </Text>
    );
  } else {
    return <Text size={'s'}>{value}</Text>;
  }
}

const BankDetailsCell = ({
  bankIdentificationCode,
  bankAccountNumber,
  accountOwner,
  needsAttention,
}: BankAccountInformationUpdateRequest) => {
  return (
    <FlexCol>
      {highlightTextIfNeedsAttention(needsAttention, bankIdentificationCode)}
      {highlightTextIfNeedsAttention(needsAttention, bankAccountNumber)}
      {highlightTextIfNeedsAttention(needsAttention, accountOwner)}
    </FlexCol>
  );
};

const MerchantCell = ({ merchantId, needsAttention }: BankAccountInformationUpdateRequest) => {
  const merchant = useMerchant(merchantId);
  return (
    <FlexCol>
      {highlightTextIfNeedsAttention(needsAttention, merchant?.data?.merchant.companyName)}
      {highlightTextIfNeedsAttention(needsAttention, merchantId)}
    </FlexCol>
  );
};
