import styled from 'styled-components';

export const FlexLeftCol = styled.div`
  width: 75%;
  padding-right: 1.8%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
`;

export const FlexRightCol = styled.div`
  width: 25%;
  padding-left: 1.8%;
  display: flex;
  padding-bottom: 5%;
  flex-direction: column;
`;

export const FilteringDiv = styled.div`
  width: 25%;
  padding-right: 1.8%;
  display: flex;
  padding-bottom: 2%;
  flex-direction: column;
`;
