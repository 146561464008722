export interface Merchant {
  merchantReference: string;
  bankInformation?: BankInformation;
  contactPerson?: ContactPerson;
  companyData?: CompanyData;
  representatives?: Representative[];
}

export interface BankInformation {
  accountOwner: string;
  accountNumber: string;
  bankCode: string;
  bankInformationType: string;
}

export interface CompanyData {
  merchantReference: string;
  companyRegistrationIdentifier: string;
  companyName: string;
  companyType: string;
  contactPerson: ContactPerson;
  address: Address;
  countryCode: string;
}

export interface Address {
  address: string;
  zipCode: string;
  city: string;
  countryCode: string;
}

export interface ContactPerson {
  email: string;
  phoneNumber: string;
  fullName: string;
}

export interface Representative {
  fullName: string;
  email: string;
}

export interface ElasticMerchant {
  merchant: MerchantData;
  onboarding: OnboardingData | null;
  sales: SalesData | null;
  acquirer: AcquirerData | null;
  contract: ContractData | null;
  forecast: ForecastData | null;
}

export interface MerchantData {
  id: string;
  timestamp: Date | null;
  registrationIdentifier: string | null;
  status: Status | null;
  companyName: string | null;
  address: ElasticAddress | null;
  businessCategory: string | null;
  businessSubCategory: string | null;
  mcc: string | null;
  mccDescription: string | null;
  contacts: ContactData[] | null;
  bankAccount: BankAccountData | null;
  settlement: SettlementData | null;
  isTestMerchant: boolean | null;
}

export interface ElasticAddress {
  address: string | null;
  city: string | null;
  country: string | null;
  zipCode: string | null;
}

export interface Status {
  code: StatusCode | null;
  timestamp: Date | null;
}

export interface OnboardingData {
  step: number | null;
  stepName: string | null;
  offerSentAt: Date | null;
  offerAcceptedAt: Date | null;
  startMonth: string | null;
  formSubmittedAt: Date | null;
  contractSignedAt: Date | null;
  transactionsEnabledAt: Date | null;
}

export interface SalesData {
  contactPersonEmail: string | null;
  contactPersonFullName: string | null;
  countryCode: string | null;
  offerRef: string | null;
}

export interface AcquirerData {
  name: Acquirer | null;
  submerchantId: string | null;
}

export enum Acquirer {
  CREDORAX = 'CREDORAX',
  ELAVON = 'ELAVON',
}

export interface ContractData {
  license: License | null;
  riskClassification: RiskClassification | null;
  riskScore: number | null;
  startDate: Date | null;
  endDate: Date | null;
  freeTerminalSubscriptionMonths: number | null;
  fees: Fees | null;
  buckets: Buckets | null;
}

export interface Fees {
  defaults: Defaults | null;
  rules: Rules | null;
}

export interface Defaults {
  cardPresent: CardPresentFee | null;
  cardNotPresent: CardNotPresentFee | null;
}

export interface Rules {
  cardPresent: CardPresentRule[] | null;
  cardNotPresent: CardNotPresentRule[] | null;
}

export interface CardPresentRule {
  fees: CardPresentFee;
  criteria: CardCriteria;
}

export interface CardNotPresentRule {
  fees: CardNotPresentFee;
  criteria: CardCriteria;
}

export interface CardPresentFee {
  feePercentage: number;
}

export interface CardNotPresentFee {
  feePercentage: number;
  fixedFeeAmount: number;
  currency: ElasticCurrency;
}

export interface CardCriteria {
  region: Region;
  cardScheme: CardScheme;
  cardCustomerType: CardCustomerType;
  cardType: CardType;
}

export interface Buckets {
  cardPresent: CardPresentBuckets | null;
}

export interface CardPresentBuckets {
  default: Bucket | null;
  dankort: Bucket | null;
  visaDebit: Bucket | null;
  mastercardDebit: Bucket | null;
  euDebit: Bucket | null;
  euCredit: Bucket | null;
}

export interface Bucket {
  feePercentage: number;
  ratio: number;
  ratioPercentage: number;
}

export enum License {
  ISO = 'ISO',
  PAYFAC = 'PAYFAC',
}

export enum RiskClassification {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  REJECT = 'REJECT',
}

export interface ContactData {
  email: string | null;
  name: string | null;
  phoneNumber: string | null;
  type: ContactType | null;
}

export enum ContactType {
  DEFAULT = 'DEFAULT',
  INVOICE = 'INVOICE',
}

export interface BankAccountData {
  accountNumber: string | null;
  bankCode: string | null;
  type: ElasticBankAccountType | null;
  owner: string | null;
  currency: ElasticCurrency | null;
  lastUpdatedAt: Date | null;
}

export interface ForecastData {
  annualTurnover: number | null;
  annualTurnoverInSEK: number | null;
  averageOrderValue: number | null;
  averageOrderValueInSEK: number | null;
  transactionsPercentageCash: number | null;
  transactionsPercentageCards: number | null;
  transactionsPercentageOther: number | null;
  annualCardTurnover: number | null;
  annualCardTurnoverInSEK: number | null;
  annualContractValue: number | null;
  annualContractValueInSEK: number | null;
}

export enum StatusCode {
  ONBOARDING = 'ONBOARDING',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DEACTIVATED = 'DEACTIVATED',
  OFFBOARDING_REQUESTED = 'OFFBOARDING_REQUESTED',
  OFFBOARDED = 'OFFBOARDED',
  ANNULLED = 'ANNULLED',
}

export interface SettlementData {
  status: SettlementStatus | null;
}

export interface SettlementStatus {
  code: SettlementStatusCode | null;
  reason: string | null;
  trigger: string | null;
  timestamp: Date | null;
}

export enum SettlementStatusCode {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
}

export enum ElasticCurrency {
  DKK = 'DKK',
  EUR = 'EUR',
  SEK = 'SEK',
  NOK = 'NOK',
}

export enum Region {
  DOMESTIC = 'DOMESTIC',
  INTRAREGIONAL = 'INTRAREGIONAL',
  INTERREGIONAL = 'INTERREGIONAL',
}

export enum CardType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum CardScheme {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
}

export enum CardCustomerType {
  CONSUMER = 'CONSUMER',
  COMMERCIAL = 'COMMERCIAL',
}

export enum JournalAccount {
  BANK_FEES = 'BANK_FEES',
  BANK_INTEREST = 'BANK_INTEREST',
  BANK_INTEREST_COSTS = 'BANK_INTEREST_COSTS',
  BANK_INTEREST_INCOME = 'BANK_INTEREST_INCOME',
  CLIENT_FUNDS_BANK_ACCOUNT = 'CLIENT_FUNDS_BANK_ACCOUNT',
  COLLECTING_BANK_ACCOUNT = 'COLLECTING_BANK_ACCOUNT',
  CREDORAX_FEE = 'CREDORAX_FEE',
  AMEX_FEE = 'AMEX_FEE',
  EXTERNAL_CASHFLOW = 'EXTERNAL_CASHFLOW',
  HARDWARE_REVENUE = 'HARDWARE_REVENUE',
  HARDWARE_VAT = 'HARDWARE_VAT',
  LDS_SOFTWARE_REVENUE = 'LDS_SOFTWARE_REVENUE',
  LDS_SOFTWARE_VAT = 'LDS_SOFTWARE_VAT',
  MERCHANT_OUTSTANDING_SUBSCRIPTIONS = 'MERCHANT_OUTSTANDING_SUBSCRIPTIONS',
  MERCHANT_PAYMENTS_UNTRACKED_INVOICES = 'MERCHANT_PAYMENTS_UNTRACKED_INVOICES',
  OUTSTANDING_SETTLEMENT_MERCHANT = 'OUTSTANDING_SETTLEMENT_MERCHANT',
  PL_BANK_ACCOUNT = 'PL_BANK_ACCOUNT',
  ROUNDING_OFF = 'ROUNDING_OFF',
  TO_BE_PAID_TO_MERCHANT = 'TO_BE_PAID_TO_MERCHANT',
  TO_BE_PAID_TO_PL_ACCOUNT = 'TO_BE_PAID_TO_PL_ACCOUNT',
  TO_BE_PAID_TO_CLIENT_FUNDS_ACCOUNT = 'TO_BE_PAID_TO_CLIENT_FUNDS_ACCOUNT',
  TO_BE_PAID_TO_COLLECTING_ACCOUNT = 'TO_BE_PAID_TO_COLLECTING_ACCOUNT',
  TO_BE_RECEIVED_FROM_CREDORAX = 'TO_BE_RECEIVED_FROM_CREDORAX',
  TO_BE_RECEIVED_FROM_AMEX = 'TO_BE_RECEIVED_FROM_AMEX',
  TO_BE_RECEIVED_FROM_MERCHANT = 'TO_BE_RECEIVED_FROM_MERCHANT',
  TO_BE_SETTLED_WITH_MERCHANT = 'TO_BE_SETTLED_WITH_MERCHANT',
  TRANSACTION_REVENUE = 'TRANSACTION_REVENUE',
  TRANSACTION_VAT = 'TRANSACTION_VAT',
  UNKNOWN_BOOKING_COLLECTING_ACCOUNT = 'UNKNOWN_BOOKING_COLLECTING_ACCOUNT',
  UNKNOWN_BOOKING_CLIENT_FUNDS_ACCOUNT = 'UNKNOWN_BOOKING_CLIENT_FUNDS_ACCOUNT',
  UNKNOWN_BOOKING_PL_ACCOUNT = 'UNKNOWN_BOOKING_PL_ACCOUNT',
  UNINVOICED_CARDS_TRANSACTION_FEE_VAT = 'UNINVOICED_CARDS_TRANSACTION_FEE_VAT',
  UNINVOICED_CARDS_TRANSACTION_REVENUE = 'UNINVOICED_CARDS_TRANSACTION_REVENUE',
}

enum ElasticBankAccountType {
  SE = 'SE',
  NO = 'NO',
  IBAN = 'IBAN',
}

export interface ElasticAddress {
  address: string | null;
  city: string | null;
  country: string | null;
  zipCode: string | null;
}

export enum EventBankAccountType {
  INTERNATIONAL_BANK_ACCOUNT = 'INTERNATIONAL_BANK_ACCOUNT',
  SWEDISH_BANK_ACCOUNT = 'SWEDISH_BANK_ACCOUNT',
  NORWEGIAN_BANK_ACCOUNT = 'NORWEGIAN_BANK_ACCOUNT',
}

export const toEventBankAccountType = {
  [ElasticBankAccountType.SE]: EventBankAccountType.SWEDISH_BANK_ACCOUNT,
  [ElasticBankAccountType.NO]: EventBankAccountType.NORWEGIAN_BANK_ACCOUNT,
  [ElasticBankAccountType.IBAN]: EventBankAccountType.INTERNATIONAL_BANK_ACCOUNT,
};

export enum StatusFilter {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  OFFBOARDED = 'OFFBOARDED',
  ANNULLED = 'ANNULLED',
}

export enum TerminalStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export interface Terminal extends Record<string, unknown> {
  terminalId: string;
  createdAt: string;
  store: string;
  model: string;
  subscriptionPrice: number;
  currency: string;
  totalCompensatedMonths: string;
  acquirerOnboardingState: AcquirerOnboardingState;
  name: string | null;
  status: TerminalStatus;
  isActive: boolean;
}

export interface TerminalsResponse extends Record<string, unknown> {
  terminals: Terminal[];
}

export interface Store extends Record<string, unknown> {
  storeId: string;
  storeName: string;
  address: string;
  city: string;
  country: string;
  amexOnboardingState: AmexOnboardingState | null;
  baxId: string | null;
  shift4SubmerchantId: string | null;
}

export interface StoresResponse {
  stores: Store[];
}

export enum LoomisLegalEntity {
  LOOMIS_DIGITAL_SOLUTIONS = 'LOOMIS_DIGITAL_SOLUTIONS',
  LOOMIS_SWEDEN = 'LOOMIS_SWEDEN',
  LOOMIS_DENMARK = 'LOOMIS_DENMARK',
  LOOMIS_SPAIN = 'LOOMIS_SPAIN',
}

export enum AmexOnboardingState {
  ONBOARDING_INITIATED = 'ONBOARDING_INITIATED',
  ONBOARDING_FAILED = 'ONBOARDING_FAILED',
  ONBOARDED = 'ONBOARDED',
  OFFBOARDING_INITIATED = 'OFFBOARDING_INITIATED',
  OFFBOARDED = 'OFFBOARDED',
}

export enum AcquirerOnboardingState {
  NOT_ONBOARDED = 'NOT_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  ONBOARDING_FAILED = 'ONBOARDING_FAILED',
}
