import { useConfiguration } from 'configuration';

export enum Permission {
  READ_MERCHANT = 'READ_MERCHANT',
  READ_FEES = 'READ_FEES',
  WRITE_FEES = 'WRITE_FEES',
  // TODO: permission to be defined
  // https://loomispay.slack.com/archives/C0530RA76NR/p1705668184796749?thread_ts=1705658391.472879&cid=C0530RA76NR
  DECLINE_REFUNDS = 'DECLINE_REFUNDS',
  READ_FINANCE = 'READ_FINANCE',
  WRITE_FINANCE = 'WRITE_FINANCE',
  READ_FILE_PROCESSING = 'READ_FILE_PROCESSING',
  WRITE_FILE_PROCESSING = 'WRITE_FILE_PROCESSING',
  READ_INVOICE = 'READ_INVOICE',
  RETRY_MERCHANT_SALESFORCE_ONBOARDING = 'RETRY_MERCHANT_SALESFORCE_ONBOARDING',
  READ_TERMINAL = 'READ_TERMINAL',
  WRITE_TERMINAL_FREE_MONTHS = 'WRITE_TERMINAL_FREE_MONTHS',
  OFFBOARD_MERCHANT = 'OFFBOARD_MERCHANT',
  RETRY_MERCHANT_ACQUIRER_ONBOARDING = 'RETRY_MERCHANT_ACQUIRER_ONBOARDING',
  IGNORE_MERCHANT_ACQUIRER_ONBOARDING = 'IGNORE_MERCHANT_ACQUIRER_ONBOARDING',
  ENABLE_AMEX = 'ENABLE_AMEX',
  ENABLE_DANKORT = 'ENABLE_DANKORT',
  ENABLE_BANKAXEPT = 'ENABLE_BANKAXEPT',
  MANAGE_MERCHANT = 'MANAGE_MERCHANT',
  DEVELOPMENT = 'DEVELOPMENT',
  WRITE_MERCHANT_REPORTING_DATA = 'WRITE_MERCHANT_REPORTING_DATA',
  TRIGGER_AMEX_SETTLEMENT = 'TRIGGER_AMEX_SETTLEMENT',
  WRITE_BANK_ACCOUNT_INFORMATION = 'WRITE_BANK_ACCOUNT_INFORMATION',
  READ_BANK_ACCOUNT_INFORMATION = 'READ_BANK_ACCOUNT_INFORMATION',
}

interface UserPermissions {
  permissions: Permission[];
  isLoading: boolean;
  isError: boolean;
}

// User has one or more permissions required to access the feature
export const hasAnyPermission = (userPermissions: Permission[], requiredPermissions: Permission[]) => {
  return requiredPermissions.some(p => userPermissions.includes(p));
};

export const useUserPermissions = (): UserPermissions => {
  const result = useConfiguration();
  return {
    permissions: result.data?.permissions || [],
    isLoading: !result.error && !result.data,
    isError: result.error,
  };
};
