import { Table } from '@loomispay/vault';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { toTitleCase } from 'utils/formatters/toTitleCase';
import { fetchByUrl } from '../../../api';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { StoreDetailsModal } from './StoreDetailsModal';
import { AmexOnboardingState, Store, StoresResponse } from '../../../api/types';

export const MerchantStoresTab = () => {
  const { t } = useTranslation();
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const merchantId = useMerchantIdFromUrl();

  const { data, mutate } = useSWR<StoresResponse>(
    [`/merchants/${merchantId}/stores`, merchantId],
    url => fetchByUrl(url),
    { refreshInterval: 10000 }
  );

  const columns = [
    { Header: t('stores.table.id'), accessor: 'storeId', minWidth: 310 },
    { Header: t('stores.table.name'), accessor: 'storeName' },
    { Header: t('stores.table.address'), accessor: 'address' },
    { Header: t('stores.table.city'), accessor: 'city' },
    {
      Header: t('stores.table.amexOnboardingState'),
      accessor: 'amexOnboardingState',
      Cell: (props: { value: AmexOnboardingState | null }) =>
        props.value === null ? null : toTitleCase(props.value.toString()),
    },
    { Header: t('stores.table.baxId'), accessor: 'baxId' },
    { Header: t('stores.table.amexSellerId'), accessor: 'amexSellerId' },
    { Header: t('stores.table.shift4SubmerchantId'), accessor: 'shift4SubmerchantId' },
  ];

  return (
    <>
      <Table
        columns={columns}
        getRowProps={row => ({
          onClick: () => {
            setSelectedStore(data?.stores?.find(it => it.storeId === row.values.storeId) || null);
          },
        })}
        data={data?.stores || []}
      />
      <StoreDetailsModal
        selectedStore={selectedStore}
        close={() => {
          setSelectedStore(null);
          mutate();
        }}
      />
    </>
  );
};
